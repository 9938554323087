<template>
    <div>
        <v-card elevation="0" class="pa-0 mt-3 mb-4 item-card item-card--secondary" @click="action">
            <v-container class="pa-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <div v-if="data.store_name" class="py-2 pl-6">{{ data.store_name }}</div>
                    </v-col>
                    <v-col cols="12">
                        <div  class="py-2 pl-6">NT${{ data.total_amounts }}
                            <span v-if="data.items">
                                / {{data.items.length}}項商品
                                (<span v-for="(d,index) in data.items.slice(0, 2)" :key="d.item_id">{{d.product}}*{{d.count}}{{index !== 1 && index !== data.items.length - 1 ? '，' : ''}} <span v-if="index === 1 && data.items.length > 2">...</span></span>
                               )
                            </span>
                        </div>
                    </v-col>

                    <v-col cols="9">
                        <div class="py-2 pl-6">{{  $helper.orderTimeFormat(data.created_at) }}</div>
                    </v-col>
                    <v-col cols="3">
                        <div class="py-2 text-center item-card-btn pointer">
                            {{ $t(`order.status.${data.status}`) }}
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import listItem from "@/components/liffList/listItem.js";

export default {
    mixins: [listItem],
    computed: {
        computedData() {
            return [
                {
                    text: "客戶",
                    value: this.data.store_name,
                },
                {
                    text: "訂單編號",
                    value: this.data.order_no,
                },
                {
                    text: "訂單時間",
                    value: this.$helper.orderTimeFormat(this.data.created_at),
                    class: this.statusColorClass,
                },
            ];
        },
        cardClass() {
            if (this.type === "primary") return "item-card--primary";
            if (this.type === "secondary") return "item-card--secondary";
            if (this.type === "lighten") return "item-card--lighten";
            if (this.type === "dark") return "item-card--dark";
            if (this.type === "error") return "item-card--error";
            return "";
        },

    },
    methods: {
        action() {
            this.$router.push({
                name: "order-detail",
                params: {orderId: this.data.order_no, providerId: this.$store.getters[`member/providerId`]},
            });
        },
    },
};
</script>

<style lang="sass">
.linerp-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important

    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0

  .item-card--primary
    .item-card-btn
      background: #009775
      color: #FFFFFF

  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #009775
      border: 1px solid #009775

.provider-store-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important

    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0

  .item-card--primary
    .item-card-btn
      background: #006DE0
      color: #FFFFFF

  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #006DE0
      border: 1px solid #006DE0

.item-card--lighten
  .item-card-btn
    background: #ffffff
    color: #00E086
    border: 1px solid #00E086

.item-card--dark
  .item-card-btn
    background: #ffffff
    color: #122A47
    border: 1px solid #122A47

.item-card--error
  .item-card-btn
    background: #ffffff
    color: #E00000
    border: 1px solid #E00000

</style>